import React from 'react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import t from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

import { theme } from "../Theme";

const campaignList = css`
  font-size: 12px;
  margin: 0;
  padding: 0;
  span {
    font-weight: bold;
  }
  ul {
    display: block;
  }
  li {
    list-style: disc inside;
    a {
    }
  }
`;

const CampaignLink = styled(CategoryLink)`
  text-decoration: none;
  color: ${theme.colors.blue};
`;

const Campaigns = ({ campaigns, className,isSale,saleTemplate, ...rest }) => {
  const { loggedIn } = useAuth();
  if ((!campaigns || campaigns.length === 0) && !isSale) {
    return null;
  }
  const filterList = ['Hööks & Friends', 'medlem', 'member'];

  let listedCampaigns = loggedIn
    ? campaigns
    : campaigns.filter((camp) => {
        if (!filterList.some((substring) => camp.name.includes(substring))) {
          return camp;
        }
      });
  if (listedCampaigns && listedCampaigns.length > 0) {
    listedCampaigns = listedCampaigns.filter((camp) => {
      if (!camp.name.startsWith('*')) {
        return camp;
      }
    });
  }


  if (isSale && Object.keys(saleTemplate).length > 0) {
    listedCampaigns = [
      ...listedCampaigns,
      {
        id: 9999,
        name: saleTemplate.saleLinkText,
        primaryRoute: saleTemplate.saleLink.primaryRoute
      }
    ];
  }

  if (listedCampaigns.length === 0) {
    return null;
  }



  return (
    <div className={cx(campaignList, className)} {...rest}>
      <span>{t('Included in campaign:')} </span>

      <ul>
        {listedCampaigns.map((campaign) =>
          campaign?.primaryRoute?.path ? (
            <li key={campaign.id}>
              <CampaignLink category={campaign}>
                {campaign.name.replace(/<!-.*?->/g, '')}
              </CampaignLink>
            </li>
          ) : (
            <li key={campaign.id}>{campaign.name.replace(/<!-.*?->/g, '')}</li>
          )
        )}
      </ul>
    </div>
  );
};

export default Campaigns;
