import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const useVisibilitySettings = (settings) => {
    const { selectedChannel } = useContext(ChannelContext);
    const countryName = selectedChannel?.country?.name.toLowerCase();
  
    // Default to checking the specific country; fall back to 'eu' if the country is not found
    const hide = settings.hasOwnProperty(countryName) ? settings[countryName] : settings['eu'];
  
    return hide;
  };

export default useVisibilitySettings;
