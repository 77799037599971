import HeroSlider from './Slider/Slider';
import HeroSlide from './Slider/Slide';
import Box, { BoxRow } from './Box';
import ColorBox, { ColorBoxRow } from './ColorBox';
import Flowbox from '../../Flowbox/FlowboxSimple';
import BestSellers from './BestSellers';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { SideBySide } from './SideBySide/SideBySide';
import { theme } from '../../Theme';
import ProductBox, { ProductRow } from './ProductBox';
import { css } from 'linaria';
import {Usps, UspItem} from '../../Cart/Usps';
import { StyledTitle } from './Title';
import { CategoryLinkRow } from './CategoryLinkRow/CategoryLinkRow';
import { CategoryLinkRowItem } from './CategoryLinkRow/CategoryLinkRowItem';
import { Spacer } from './Spacer';
import ChannelContext from '@jetshop/core/components/ChannelContext';


export const standardText = css`
  p {
    font-family: ${theme.fonts.primary};
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    letter-spacing: 0.02em;
  }

  ul,
  ol {
    font-family: ${theme.fonts.primary};
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
    letter-spacing: 0.02em;
  }
  ul,
  ol {
    list-style: revert !important;
  }
`;

const FlowboxWrapper = styled('div')`
  background: ${props => (props.hideBg ? 'transparent' : '#f7f7f7')};
  padding: 30px 0 30px 0;
  margin: 0 0 40px 0;
  text-align: center;
  .flowbox-content {
    max-width: 700px;
    margin: auto;
    margin-bottom: 20px;
    padding: 0 20px;
    text-align: center;
    a {
      color: black;
      text-decoration: underline;
    }
    h2 {
      text-transform: uppercase;
      line-height: 1.1;
      margin-bottom: 10px;
    }
  }
  .flowbox-wrapper {
    max-width: 93rem;
    margin: 0 auto;
  }
  .flowbox-cta {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-radius: 0;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    height: 45px;
    line-height: 44px;
    display: inline-flex;
    transition: all, 0.2s ease;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 0 1rem;
    background: transparent;
    color: black;
    border: 1px solid black;
    text-transform: uppercase;
    font-size: 16px;
    &:hover {
      background: ${theme.colors.accent};
      color: white;
      border-color: ${theme.colors.accent};
    }
  }
`;

const LimitedWidth = styled('div')`
  max-width: 94rem;
  width: 100%;
  margin: auto;
  padding: 3rem 2rem;
  &.gmf-start-page-slideshow {
    ${theme.below.xl} {
      padding: 2rem 0rem;
    }
  }
`;

const FlowboxComponent = ({
  feed,
  title,
  text,
  ctaText,
  ctaLink,
  hideBg,
  menuTags
}) => {
  const tags = menuTags?.value?.split(',').map(tag => tag.trim()) ?? [];

  return (
    <FlowboxWrapper hideBg={hideBg?.value}>
      <div className="flowbox-content">
        {title?.value && <h2>{title?.value}</h2>}
        <div dangerouslySetInnerHTML={{ __html: text?.value }} />
      </div>
      <Flowbox feed={feed?.value || 'dfsL5w_NT0qEZq7Bh5RdLQ'} menuTags={tags} />
      {ctaLink?.value && (
        <Link className="flowbox-cta" to={ctaLink?.value}>
          {ctaText?.value}
        </Link>
      )}
    </FlowboxWrapper>
  );
};

const CommunityActivity = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const isEu = selectedChannel.id === 2;

  if(isEu) return null;
  return (
  <LimitedWidth className="gmf-start-page-slideshow"/>
)};


export const allComponents = {
  HEROSLIDER: HeroSlider,
  HEROSLIDE: HeroSlide,
  BOXROW: BoxRow,
  BOX: Box,
  COLORBOX: ColorBox,
  COLORBOXROW: ColorBoxRow,
  FLOWBOXROW: FlowboxComponent,
  BESTSELLERS: BestSellers,
  SIDEBYSIDE: SideBySide,
  PRODUCTBOX: ProductBox,
  PRODUCTROW: ProductRow,
  'COMMUNITY ACTIVITY': CommunityActivity,
  TITLE: StyledTitle,
  CATEGORYLINKROW: CategoryLinkRow,
  CATEGORYLINKROWITEM: CategoryLinkRowItem,
  SPACER: Spacer
};

export const cartComponents = {
  USPS: Usps,
  HTMLCONTENT: UspItem
};


