import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Price } from '../Price';
import { ProductCard } from './ProductCard';

import { theme } from '../Theme';

const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -${theme.space[1]};
  margin-left: -${theme.space[1]};
  width: 100%;

  .new-price {
    color: #b13827;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'RedRose';
    font-size: 1.5rem;
    line-height: 1rem;
  }
  .price {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'RedRose';
    font-size: 1.5rem;
    line-height: 1rem;
    margin-right: 0;
  }
  .old-price {
    color: ${theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .product-card {
    line-height: 19px;
    min-width: 0;
    /* 
       * For browsers that do not support grid, we need to specify the width of
       * each product
       */
    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin-bottom: 2em;
    margin-right: 0px;
    margin-left: 0px;
    .product-card {
      width: auto;
      a {
        margin: 0;
      }
    }

    ${theme.below.lg} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export function ProductGrid({
  id,
  products,
  prevOffset,
  listName,
  loading,
  className,
  ...rest
}) {

  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products?.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            {...rest}
          />
        );
      })}
    </Wrapper>
  );
}
